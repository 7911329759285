import React from 'react';

import {TitleBar} from 'components';

import {useLicenses} from '@renofi/api';
import {Loader, Toggle} from '@renofi/components';

import {Container} from '../App';

const RmppEligibleStates = () => {
  const {licenses, loading} = useLicenses();
  const mappedStates = licenses.map(({state}) => state);
  const uniqueStates = Array.from(new Set(mappedStates));

  return (
    <>
      <TitleBar title="RMPP Eligible Property States"></TitleBar>

      <Container>
        <Toggle show={loading}>
          <Loader />
        </Toggle>

        <ul>
          {uniqueStates.map((state) => (
            <li key={state}>{state}</li>
          ))}
        </ul>
      </Container>
    </>
  );
};

export default RmppEligibleStates;
